<template>
  <div>
    <ListWebinars :showInBackOffice="true" />
  </div>
</template>

<script>
import ListWebinars from '@/views/webinars/ListWebinars.vue'

export default {
 components: {
    ListWebinars,
},
}
</script>

<style>

</style>